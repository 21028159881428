import React from "react";

type Props = {
  height?: number;
};

export default class Divider extends React.PureComponent<Props> {
  render() {
    const { height } = this.props;
    return <div style={{ height: `${height ? `${height}px` : "27px"}` }} />;
  }
}
