import React from 'react'
import { Typography, Button } from '@material-ui/core'

import Divider from '../components/Common/Divider'
import NormalLink from '../components/Common/NormalLink'

type Props = {
  statusCode: any
}

const NotFound = ({ statusCode }: Props) => {
  return (
    <div>
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <img
            src="https://swap-img.swap.work/media/server-down.png"
            className="server_down"
            style={{
              width: '270px',
              padding: '1rem',
              borderRadius: '2px',
            }}
          />
          <Divider height={6} />
          <Typography variant="body2">
            {statusCode
              ? `伺服器發生錯誤，錯誤代碼為 ${statusCode}`
              : '您訪問的頁面不存在或是發生錯誤。請確認網址是否輸入正確'}
          </Typography>
          <Divider />
          <NormalLink href="/">
            <Button variant="contained" color="primary">
              返回首頁
            </Button>
          </NormalLink>
        </div>
      </div>
    </div>
  )
}

export default NotFound
